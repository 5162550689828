import React from "react"
import Helmet from "react-helmet"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/contact.css"

import ContactUsBanner from "../images/contact-us-image.svg"

const ContactPage = () => (
  <Layout>
    <Helmet
      bodyAttributes={{
        class: "full-page contact-us-page",
      }}
    />
    <SEO title="contact us" />
    <section className="section-intro">
      <div className="section--content align-items-center">
        <div className="home--left--section">
          <h1 className="home--banner-text">
            Contact Us @ <br />
            your convenience
          </h1>
          <h2 className="home--banner-text-sub">We are available 24/7</h2>
          <h4 className="home--banner-text-small">
            Facing any troubles? <br /> use the form below to contact our
            support and <br />
            be rest assured we will reply asap.
          </h4>
        </div>
        <div className="home--right--section">
          <div className="home-delivery--illustration">
            <img src={ContactUsBanner} alt="" />
          </div>
        </div>
      </div>
    </section>
    <section className="contact-us-block">
      <div
        className="section--content flex-section"
        style={{ alignItems: "baseline" }}
      >
        <h2 className="section--content--header">Contact Us</h2>
        <div className="contact-us-form">
          <div className="form--control">
            <input type="text" placeholder="Full Name" />
          </div>
          <div className="form--control">
            <input type="text" placeholder="Email" />
          </div>
          <div className="form--control textarea">
            <textarea placeholder="comment"></textarea>
          </div>
          <button className="cta submit-form">Submit</button>
        </div>
        <div className="contact-address-area">
          <div className="blk--section">
            <h3 className="blk--section--lbl">Office Address</h3>
            <p className="">Suite D82 Dolphin Plaza. Dolphin estate Ikoyi</p>
          </div>
          <div className="blk--section">
            <h3 className="blk--section--lbl">Email</h3>
            <p className="">hello@muvit.com.ng</p>
          </div>
          <div className="blk--section">
            <h3 className="blk--section--lbl">Phone Number</h3>
            <p className="">014546880</p>
          </div>
        </div>
      </div>
    </section>
    <section className="partner-contact">
      <div className="section--content">
        <div className="partner-contact-info">
          <div className="other-section">
            <h1 className="partner-contact-header">Partner Contact</h1>
            <h2 className="business-contact">
              If you have any enquiries with MUVIT Business, please contact our
              logistics support. Service hours:
            </h2>
          </div>

          <div className="leftSection">
            <ul className="partner-contact-list">
              <li>
                <span className="partner-contact-detail-list">Opened</span>
                <span className="contact-detail-value">
                  Monday - Saturday <br /> 09:00-18:00
                </span>
              </li>
              <li>
                <span className="partner-contact-detail-list">Email</span>
                <span className="contact-detail-value">
                  partners@muvit.com.ng
                </span>
              </li>
              <li>
                <span className="partner-contact-detail-list">Hotline</span>
                <span className="contact-detail-value">014546880</span>
              </li>
              <li>
                <span className="partner-contact-detail-list">
                  Whatsapp Only
                </span>
                <span className="contact-detail-value">08126705621</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
export default ContactPage
